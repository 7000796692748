.main-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.map-container {
  position: absolute;
  top: 0;
  left: 19.25rem;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: left ease-in-out 250ms;
}






.sidebar {
  position: relative;
  height: 100vh;
  z-index: 1;
  width: 20.5rem;
  background-color: #fcfcfc;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s;
  border-radius: 0 1.4rem 1.4rem 0;
  box-shadow: 0 0 2rem .05rem rgba(50, 50, 50, 0.15);
}

.sidebar>.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar.expanded {
  width: 26rem;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  padding: 0 1.5rem 0 1.5rem;
  z-index: 1;
}


.sidebar-header-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.sidebar-header-title h1 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.sidebar-header-title h1.padded {
  margin: 0 .66rem;
}

.sidebar-header-title .button {
  flex-shrink: 0;
}

.sidebar .button {
  cursor: pointer;
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  font-size: 1.5rem;
  transition: background ease-in-out 250ms;
}

.sidebar .button:hover {
  background-color: #f1f1f1;
}

.sidebar-header-actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.sidebar-content {
  padding: 0 1.5rem;
  overflow-y: auto;
  position: absolute;
  top: 5rem;
  bottom: 0;
  left: 0;
  right: 2px;
}

.sidebar-item {
  display: block;
  width: 100%;
  cursor: pointer;
  margin: 0 0 1.15rem 0;
  height: 8.5rem;
  background-color: #418ed6;
  border-radius: .85rem;
  position: relative;
  overflow: hidden;
}

.sidebar-item.expanded {
  height: 9.5rem;
}

.sidebar-item:hover>.sidebar-item-container {
  transform: scale(1.075);
}

.sidebar-item:hover>.sidebar-item-container>.sidebar-item-overlay {
  background-color: rgba(0, 0, 0, .2) !important;
}

.sidebar-item-overlay {
  transition: background-color 200ms ease-in;
}

.sidebar-item .sidebar-item-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 200ms ease-in;
}

.sidebar-item .sidebar-item-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.sidebar-item .sidebar-item-container .sidebar-item-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .35);
}

.sidebar-item-content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff;
  white-space: nowrap;
  padding: .7rem;
}

.sidebar-item-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-item-subtitle {
  margin: .3rem 0 0 0;
  font-size: .95rem;
  font-weight: 400;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-image {
  overflow: hidden;
  display: block;
}

.ant-image {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 .75rem 0;
  background-color: grey;
  border-radius: .5rem;
  background-size: cover;
}

.search-result-item {
  display: block;
  margin: 0;
  font-size: 1rem;
  padding: .2rem .25rem;
}

.search-result-item p {
  display: block;
  margin: 0;
  padding: 0;
  font-size: .8rem;
}

.search-result-item b {
  display: block;
  margin: 0;
  padding: 0;
  font-size: .9rem;
  line-height: 1;
  font-weight: 500;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}

*::-webkit-scrollbar-thumb {
  border-radius: 2.5rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.shadow {
  filter: drop-shadow(0 .1rem .3rem rgba(50, 50, 50, .1));
}

.icon-picker-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 39px;
  width: 39px;
  border-radius: 6px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;
}

.icon-picker-btn:hover {
  border-color: #0099a3;
}

.icon-picker-btn:focus {
  border-color: #006d75;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

.ant-upload-wrapper .ant-upload-drag {
  height: 10rem;
  border-radius: .75rem;
  margin: 0 0 1.15rem 0;
}

.image-stack {
  background-color: red;
  position: relative;
  z-index: 99;
  top: 1.2rem;
  transition: top ease .5s;
}

.image-stack:hover,
.image-stack.expanded {
  top: 8.2rem;
}

.image-stack-item {
  display: block;
  background-color: #fff;
  padding: .75rem .75rem 1.25rem .75rem;
  position: absolute;
  border-radius: .2rem;
  box-shadow: 1rem 1rem .5rem 0rem rgba(50, 50, 50, 0.15);
  transition: transform ease .5s, box-shadow ease .5s;
  width: 24rem !important;

}

.image-stack-item:nth-child(1) {
  transform: rotate(-3deg) translate(-1rem, 0rem) scale(.85);
  z-index: 10;
}

.image-stack-item:nth-child(2) {
  transform: rotate(6deg) translate(-2rem, .5rem) scale(.85);
  z-index: 5;
}

.image-stack-item:nth-child(3) {
  transform: rotate(-5.5deg) translate(1rem, 1rem) scale(.85);
  z-index: 4;
}

.image-stack-item img {
  border-radius: 0;
  max-width: 100%;
}

.image-stack:hover .image-stack-item:nth-child(1),
.image-stack.expanded .image-stack-item:nth-child(1) {
  transform: rotate(2deg) translate(0rem, -5rem) scale(1.2);
  box-shadow: 1rem 1rem 1rem .15rem rgba(50, 50, 50, 0.25);
}

.image-stack:hover .image-stack-item:nth-child(2),
.image-stack.expanded .image-stack-item:nth-child(2) {
  transform: rotate(-4deg) translate(-10rem, -2rem) scale(1.1);
  box-shadow: 1rem 1rem 1rem .15rem rgba(50, 50, 50, 0.25);
}

.image-stack:hover .image-stack-item:nth-child(3),
.image-stack.expanded .image-stack-item:nth-child(3) {
  transform: rotate(8deg) translate(10rem, -2.5rem) scale(1.15);
  box-shadow: 1rem 1rem 1rem .15rem rgba(50, 50, 50, 0.25);
}


.auth {
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.auth_background {
  flex-grow: 1;
  background-image: url('../public/login_background.jpg');
  background-size: cover;
}

.auth_background img {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  height: 4.5rem;
}

.auth_content {
  background-color: #f7f7f7;
  width: 38rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
  box-shadow: 0 0 2rem .05rem rgba(50, 50, 50, 0.2);
}

.auth_content h1 {
  margin-bottom: .7rem;
}

.auth_content h2 {
  margin-bottom: 1.25rem;
  color: #666;
}

.auth_content div {
  width: 100%;
}

@media screen and (max-width: 750px) {
  .auth_content {
    width: 100% !important;
  }

  .auth_content {
    padding: 2rem 2rem !important;
  }
}

.loader {
  display: none;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.loader.loading {
  display: flex;
  font-size: 6rem;
}

.loader.loading * {
  font-size: 6rem;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: normal;
}

.marker-pin {
  width: 37px;
  height: 37px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -51px 0 0 -20px;
  cursor: pointer;
}

.marker-pin .marker-pin-inner {
  content: '';
  width: 30px;
  height: 30px;
  margin: 4px 0 0 4px;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-pin .marker-pin-inner > * {
  transform: rotate(45deg);
}




.ant-alert {
  margin-bottom: 1rem;
}


.gallery-toolbar {
  font-size: 1.75rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: .7rem;
  background-color: rgba(0, 0, 0, 0.1);

}

.gallery-toolbar .ant-space-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.empty img {
  width: 70%;
  margin-bottom: .75rem;
}

.empty h1 {
  width: 70%;
  font-size: 1.65rem;
  font-weight: 500;
  margin-bottom: .25rem;
}

.empty p {
  margin: 0;
  width: 70%;
  margin-bottom: .75rem;
}


@media only screen and (max-width: 768px) {

  .map-container {
    left: 0;
    bottom: 0;
  }

  .sidebar.expanded {
    width: 100%;
    height: 33vh;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1.4rem 1.4rem 0 0;
  }

  /* Mobile optimizations */
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  /* Allow selection in input fields */
  input,
  textarea {
    -webkit-user-select: text;
    user-select: text;
  }

  /* Smooth scrolling and momentum */
  .sidebar-content {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  /* Disable pull-to-refresh */
  html,
  body {
    overscroll-behavior-y: none;
  }

  /* Better touch targets */
  button,
  .button,
  .sidebar-item {
    min-height: 44px;
    min-width: 44px;
  }

  /* Native-like active states */
  button:active,
  .button:active,
  .sidebar-item:active {
    opacity: 0.7;
    transition: opacity 0.2s;
  }

  /* Disable long-press context menu */
  .sidebar-item img  {
    -webkit-touch-callout: none;
    pointer-events: none;
  }

  /* Fix 100vh issue on mobile browsers */
  .main-page,
  .sidebar {
    height: 100dvh;
  }

  /* Add rubber band effect to scrollable areas */
  .sidebar-content {
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Ensure proper spacing around notches */
  .sidebar-header {
    padding-top: env(safe-area-inset-top);
  }
}