body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #222;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  scroll-behavior: smooth;

  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-weight: 500;
  font-size: 1.75rem;
  margin: 0;
}

h2 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  margin: 0;
}